import React, { Component } from 'react';
import Icon from 'react-icons-kit';
import { menu } from 'react-icons-kit/iconic/menu';

export default class MobileMenuButton extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { showMobileMenu } = this.props;

    showMobileMenu();
  }

  render() {
    return (
      <Icon icon={menu} onClick={this.handleClick}/>
    );
  }
}
