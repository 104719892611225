import React from 'react';
import { Link } from 'gatsby';

import GetStartedButton from '../GetStartedButton';
import MobileMenuButton from '../MobileMenuButton';
import Logo from '../../images/dark_logo_transparent.png';

import './Navbar.styles.scss';

const Navbar = () => {
  return (
    <nav className="navbar">
        <div className="navbar__left">
            <div className="navbar__logo">
                <Link to="/">
                    <img src={Logo} alt="Transferable logo" />
                </Link>
            </div>
        </div>
        <div className="navbar__right">
            <ul className="navbar__list">
                <li className="navbar__list-item">
                    <Link to="/">
                      Home
                    </Link>
                </li>
                <li className="navbar__list-item">
                    <Link to="/about">
                      About
                    </Link>
                </li>
                <li className="navbar__list-item">
                    <Link to="/public-benefit">
                      Public Benefit
                    </Link>
                </li> 
                <li className="navbar__list-item">
                    <Link to="/blog">
                      Blog
                    </Link>
                </li>
                <li className="navbar__list-item">
                    <Link to="/resources">
                      Resources
                    </Link>
                </li>
                <li className="navbar__list-item">
                    <Link to="/contact">
                      Contact us
                    </Link>
                </li>
                <li className="navbar__list-item">
                    <GetStartedButton />
                </li>

                <li className="navbar__list-item mobile-menu-btn">
                  <MobileMenuButton />
                </li>
            </ul>
        </div>
    </nav>
  );
};

export default Navbar;
