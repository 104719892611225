import { connect } from 'react-redux';
import { hideGetStartedForm, submitGetStartedForm } from '../../state/actions/getStartedFormActions';
import GetStartedForm from './GetStartedForm';

const mapStateToProps = state => ({
    isGetStartedFormVisible: state.getStartedForm.isGetStartedFormVisible,
    getStartedFormSubmittedSuccessfully: state.getStartedForm.getStartedFormSubmittedSuccessfully
});

export default connect(
    mapStateToProps,
    { hideGetStartedForm, submitGetStartedForm }
)(GetStartedForm);