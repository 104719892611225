import React from 'react';
import Select from 'react-select';

import './GetStartedFormSelectMenu.styles.scss';

const GetStartedFormSelectMenu = ({ value, handleChange }) => {
    const options = [
        { value: 'student', label: 'Student' },
        { value: 'community-college', label: 'Community College' },
        { value: 'university', label: 'University' },  
    ];


    return (
        <div className="get-started-form-select-menu-wrapper">
            <label
                className="get-started-form-select-menu__label"
                htmlFor="select-menu"
            >
                I am a
            </label>
            <Select 
                value={value}
                onChange={handleChange}
                name="select-menu"
                options={options}
                placeholder="----"
                className="get-started-form-select-menu"
            />
        </div>
    );
};

export default GetStartedFormSelectMenu;