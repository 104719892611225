import React, { Component } from 'react';
import GetStartedForm from '../GetStartedForm';

import './GetStartedFormModal.styles.scss';

export default class GetStartedFormModal extends Component {
  render() {
    const { isGetStartedFormVisible } = this.props;
    const isVisible = isGetStartedFormVisible ? 'show' : 'hide';
    return (
      <div className={`get-started-form-modal ${isVisible}`}>
        <GetStartedForm />
      </div>
    );
  }
}
