import React from 'react';
import { Link } from 'gatsby';

import './Footer.styles.scss';

const Footer = () => {
  return (
    <div className="footer">
      <ul className="footer__nav-links-list">
        <li className="footer__nav-link">
          <Link to="/">
            Home
          </Link>
        </li>
        <li className="footer__nav-link">
          <Link to="/about">
            About
          </Link>
        </li>
        <li className="footer__nav-link">
          <Link to="/public_benefit">
            Public Benefit
          </Link>
        </li>
        <li className="footer__nav-link">
          <Link to="/blog">
            Blog
          </Link>
        </li>
        <li className="footer__nav-link">
          <Link to="/resources">
            Resources
          </Link>
        </li>
        <li className="footer__nav-link">
          <Link to="/contact">
            Contact us
          </Link>
        </li>
        <li className="footer__nav-link">
          <Link to="/references">
            References
          </Link>
        </li>
      </ul>
      <p className="footer__text">
        © 2019 Transferable. All rights reserved, Transferable LLC
      </p>
    </div>
  );
};

export default Footer;
