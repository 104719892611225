import React, { Component } from 'react';
import { Link } from 'gatsby';

// Components
import MobileMenuCloseButton from '../MobileMenuCloseButton';
import GetStartedButton from '../GetStartedButton';

// Assets
import Logo from '../../images/white_logo_transparent.png';

// Styles
import './MobileMenu.styles.scss';

const linksData = [
  {
    url: '/',
    label: 'Home'
  },
  {
    url: '/about',
    label: 'About'
  },  
  {
    url: '/public-benefit',
    label: 'Public Benefit'
  },
  {
    url: '/blog',
    label: 'Blog'
  },
  {
    url: '/resources',
    label: 'Resources'
  },
  {
    url: '/contact',
    label: 'Contact us'
  },
];

class MobileMenu extends Component {
  render() {
    const { isMobileMenuVisible, hideMobileMenu } = this.props;

    return (
      <div className={`mobile-menu ${isMobileMenuVisible ? 'show' : 'hide'}`}>
        <div className="mobile-menu__header">
          <span className="mobile-menu__logo">
            <img src={Logo} alt="Transferable logo" />
          </span>

          <MobileMenuCloseButton />
        </div>

        <div className="mobile-menu__body">
          <ul className="mobile-menu__nav-list">
          {linksData.map(link => (
            <li className="mobile-menu__nav-list-item" onClick={() => hideMobileMenu()}>
              <Link to={link.url}>
                {link.label}
              </Link>
            </li>
          ))}


            <li className="mobile-menu__nav-list-item">
              <GetStartedButton landing={'landing'} />
            </li>
          </ul>
        </div>
      </div>
    );
  }
}


export default MobileMenu;