import React, { Component } from 'react';
import { Icon } from 'react-icons-kit';
import {ic_close} from 'react-icons-kit/md/ic_close';

export default class MobileMenuCloseButton extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { hideMobileMenu } = this.props;

    hideMobileMenu();
  }

  render() {
    return (
        <Icon 
            icon={ic_close}
            size={32}
            style={{ color: 'white', cursor: 'pointer' }}
            onClick={this.handleClick} 
        />
    );
  }
}
