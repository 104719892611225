import React, { Component } from 'react';
import { css } from '@emotion/core';
import { BeatLoader } from 'react-spinners';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  transition: all .5s ease;
`;

export default class SubmittingSpinner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };
  }
  render() {
    return (
      <div className="loading-spinner">
        <BeatLoader
          css={override}
          size={5}
          color={'#fff'}
          loading={this.state.loading}
        />
      </div>
    );
  }
}
