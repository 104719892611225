import React, { Component } from 'react';
import { Link } from 'gatsby';

// Components
import GetStartedButton from '../GetStartedButton';
import MobileMenuButton from '../MobileMenuButton';
import Logo from '../../images/dark_logo_transparent.png';

// Styles
import './BlogNav.styles.scss';

const BlogNav = () => {
  return (
    <nav className="blog-nav">
      <div className="blog-nav__left">
        <span className="blog-nav__logo">
          <img src={Logo} alt="Transferable logo" />
        </span>
        <span className="blog-nav__left-title">
          / Blog
        </span>
      </div>
      <div className="blog-nav__right">
      <span className="blog-nav__link">
          <Link to="/">
            Home
          </Link>
        </span>
      <span className="blog-nav__link">
          <Link to="/about">
            About
          </Link>
        </span>
        <span className="blog-nav__link">
          <Link to="/public-benefit">
            Public Benefit
          </Link>
        </span>
        <span className="blog-nav__link">
          <Link to="/blog">
            Blog
          </Link>
        </span>
        <span className="blog-nav__link">
          <Link to="/resources">
            Resources
          </Link>
        </span>
        <span className="blog-nav__link">
          <Link to="/contact">
            Contact us
          </Link>
        </span>
        <span className="blog-nav__link">
          <GetStartedButton />
        </span>

        <span className="blog-nav__link mobile-menu-btn">
          <MobileMenuButton />
        </span>
      </div>
    </nav>
  );
};

export default BlogNav;
