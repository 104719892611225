import React from 'react';

import './GetStartedFormInput.styles.scss';

const GetStartedFormInput = (props) => {
  return (
    <div className={`get-started-form-input__container ${props.long}`}>
      <input
        className="get-started-form-input__input"
        name={props.name}
        type={props.type}
        value={props.value}
        onChange={props.handleChange}
        placeholder={props.placeHolder}
      />
    </div>
  );
};

export default GetStartedFormInput;
