import { connect } from 'react-redux';

// Actions
import { showGetStartedForm } from '../../state/actions/getStartedFormActions';

// Component
import GetStartedButton from './GetStartedButton';

const mapStateToProps = state => ({
    isGetStartedFormVisible: state.getStartedForm.isGetStartedFormVisible
});

export default connect(mapStateToProps, { showGetStartedForm })(GetStartedButton);