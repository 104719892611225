import React, { Component } from 'react';
import onClickOutside from "react-onclickoutside";
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

import GetStartedFormInput from '../GetStartedFormInput';
import GetStartedFormSelectMenu from '../GetStartedFormSelectMenu';
import GetStartedFormButton from '../GetStartedFormButton';
import FormErrors from '../FormErrors';

import './GetStartedForm.styles.scss';

class GetStartedForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fullName: '',
      email: '',
      userType: '',
      formErrors: {
        name: ' is empty. Please enter your full name.',
        email: ' is invalid. Please enter a valid email address.'
      },
      fullNameValid: false,
      emailValid: false,
      formValid: false,
      errorsVisible: false
    };
  }

  handleClickOutside = e => {
    const { isGetStartedFormVisible, hideGetStartedForm } = this.props;

    if (isGetStartedFormVisible) {
      hideGetStartedForm();
    }
  }

  handleFormSubmit = e => {
    const { submitGetStartedForm } = this.props;
    const { fullName, email, userType } = this.state;
    const subject = 'Get Started Form';
    e.preventDefault();
    submitGetStartedForm({
      subject,
      fullName,
      email,
      userType: userType.label
    });
  }

  handleFullName = e => {
    const { name, value } = e.target;

    this.setState({
      fullName: value
    }, () => {
      this.validateField(name, value);
    });
  }

  handleEmail = e => {
    const { name, value } = e.target;

    this.setState({
      email: value
    }, () => {
      this.validateField(name, value);
    });
  }

  handleUserType = selectedOption => {
    this.setState({
      userType: selectedOption
    });
  };

  validateField = (fieldName, value) => {
    let {
      formErrors: fieldValidationErrors,
      fullNameValid,
      emailValid,
    } = this.state;

    switch (fieldName) {
      case 'Full name':
        fullNameValid = value.length > 0;
        fieldValidationErrors.name = fullNameValid ? '' : ' is empty. Please enter your full name.';
        break;
      case 'Email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) !== null;
        fieldValidationErrors.email = emailValid ? '' : ' is invalid. Please enter a valid email address.';
        break;
      default:
        break;
    }

    this.setState({
      formErrors: fieldValidationErrors,
      fullNameValid,
      emailValid
    }, () => {
      this.validateForm();
    });
  }

  validateForm = () => {
    const { fullNameValid, emailValid, formValid } = this.state;

    this.setState({
      formValid: fullNameValid && emailValid
    });
  }

  showErrorsOnFailedSubmit = () => {
    this.setState({
      errorsVisible: true
    });
  }

  renderGetStartedForm = () => {
    return (
      <form
        className="get-started-form__form"
        autoComplete="off"
        onSubmit={this.handleFormSubmit}
        noValidate
      >

      
      <span className="get-started-form__form-row">
        <GetStartedFormInput
            name="Full name"
            type="full_name"
            value={this.state.fullName}
            handleChange={this.handleFullName}
            placeHolder="Full name"
            long="long"
        />
      </span>
        <span className="get-started-form__form-row">
            <GetStartedFormInput
                name="Email"
                type="email"
                value={this.state.email}
                handleChange={this.handleEmail}
                placeHolder="Email"
                long="long"
            />
        </span>
        <span className="get-started-form__form-row">
            <GetStartedFormSelectMenu
                value={this.state.userType}
                handleChange={this.handleUserType}
            />
        </span>


        <span className="get-started-form__form-row" style={{ marginTop: '2rem' }}>
            <GetStartedFormButton
                formValid={this.state.formValid}
                showErrors={this.showErrorsOnFailedSubmit}
            />
        </span>

        <div className="get-started-form__errors-panel">
            <FormErrors 
                formErrors={this.state.formErrors} 
                errorsVisible={this.state.errorsVisible} 
            />
        </div>
      </form>
    );
  }

  renderThankYouMessage = () => {
    return (
      <Fade clear>
        <div className="get-started-form__submitted-message-container">
          <span>
            {'Thanks for your interest! '}
          </span>


          <span style={{ color: 'rgba(255, 140, 0, 1)' }}>
            {'We\'ll be in touch soon.'}
          </span>
        </div>
      </Fade>
    );
  }

  render() {
    const { getStartedFormSubmittedSuccessfully } = this.props;
    const submittedSuccessfully = getStartedFormSubmittedSuccessfully ? 'submittedSuccessfully' : null;

    return (
      <Zoom clear>
        <div className={`get-started-form ${submittedSuccessfully}`}>
          <div className="get-started-form__header">
            <h2 className="get-started-form__title">
              Transferring is hard.
              It doesn't <br /> have to be.
            </h2>

            <p className="get-started-form__subtitle" style={{ marginBottom: '.25rem' }}>
              Want to learn more?
            </p>

            <p className="get-started-form__subtitle">
              Interested in participating in our pilot?
            </p>
          </div>

          <div className="get-started-form__form-container">
            {
              getStartedFormSubmittedSuccessfully ?
                this.renderThankYouMessage() :
                  this.renderGetStartedForm()
            }
          </div>

        </div>
      </Zoom>
    );
  }
}


export default onClickOutside(GetStartedForm);
