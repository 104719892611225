/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
//import { useStaticQuery, graphql } from "gatsby"

import MobileMenu from '../../components/MobileMenu';
import GetStartedFormModal from '../../components/GetStartedFormModal';
import Navbar from '../Navbar';
import BlogNav from '../BlogNav';
import Footer from '../Footer';

import './Layout.styles.scss';

const Layout = ({ currentPath, children }) => {
  /*
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  */

  return (
    <>
      <GetStartedFormModal />
      <MobileMenu />
      {
        // If on landing page (currentPath === '/'), do not display a navigation component.
        // Otherwise, check whether to use <BlogNav /> or standard <Navbar /> 
        (
          currentPath === '/' ||
          currentPath === '/about' ||
          currentPath === '/public-benefit' ||
          currentPath === '/resources'
        ) ? null : 
          (
            currentPath === '/blog' || currentPath === '/blog-post' ? 
              <BlogNav /> : <Navbar />
          )
      }
      <main>
        {children}
      </main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;
