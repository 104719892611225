import axios from 'axios';

import { 
    SHOW_GET_STARTED_FORM,
    HIDE_GET_STARTED_FORM,
    GET_STARTED_FORM_SUBMIT_BEGIN,
    GET_STARTED_FORM_SUBMIT_SUCCESS,
    GET_STARTED_FORM_SUBMIT_ERROR,
    GET_STARTED_FORM_RESET,
} from '../types';

const API_URL = 'https://transferable-blog.herokuapp.com';

export const showGetStartedForm = () => ({
    type: SHOW_GET_STARTED_FORM
});

export const hideGetStartedForm = () => ({
    type: HIDE_GET_STARTED_FORM
});

const getStartedFormSubmitBegin = () => ({
    type: GET_STARTED_FORM_SUBMIT_BEGIN
});

const getStartedFormSubmitSuccess = () => ({
    type: GET_STARTED_FORM_SUBMIT_SUCCESS
});

const getStartedFormSubmitError = () => ({
    type: GET_STARTED_FORM_SUBMIT_ERROR
});

export const submitGetStartedForm = formData => async dispatch => {
    try {
        dispatch(getStartedFormSubmitBegin());
        const response = await axios.post(`https://transferable-email-api.herokuapp.com/email`, formData);
        dispatch(getStartedFormSubmitSuccess());
    } catch (err) {
        dispatch(getStartedFormSubmitError());
    }
};

export const getStartedFormReset = () => ({
    type: GET_STARTED_FORM_RESET
});